.page .content {
  padding-bottom: 48px;
  img {
    max-width: 100%;
  }

  h1 {
    max-width: 95%;
    font-variant: normal;
    margin-top: -16px;
    padding-bottom: 6px;
    margin-top: 64px;
    margin-bottom: 24px;
    border-bottom: 1px dotted $gray_aa;
    font-weight: 300;
    font-size: 36px;
    text-transform: uppercase;
  }
}



/* HEADER LINKS 
   ----------------------------------------------------------------------- */

h4, h5, h6 {
  margin-top: 20px;
  margin-bottom: 8px;
}
h5 {
  font-size: 18px;
  font-weight: 300;
}
h6 {
  font-size: 16px;
}

/*
 * This code is courtesy Ben Balter, modified by Parker Moore for jekyllrb.com
 * http://ben.balter.com/2014/03/13/pages-anchor-links/
 */
.header-link {
  position: relative;
  left: 0.5em;
  opacity: 0;
  font-size: 0.8em;

/* not using -- but this is cool!
  -webkit-transition: opacity 0.2s ease-in-out 0.1s;
  -moz-transition: opacity 0.2s ease-in-out 0.1s;
  -ms-transition: opacity 0.2s ease-in-out 0.1s;
*/
}
h2:hover .header-link,
h3:hover .header-link,
h4:hover .header-link,
h5:hover .header-link,
h6:hover .header-link {
  opacity: 1;
}
a.header-literal-anchor { display: inline !important; }
