/* YAML EXPLAINED
   ----------------------------------------------------------------------- */

$explained_code_bg_color: $gray_66;
$explained_code_fg_color: $white_dd;
$explained_code_highlight_bg_color: $vibrant_bg_green; 
$explained_code_highlight_fg_color: $white_fa;
$explained_text_highlight_fg_color: $text_color;
$explained_text_highlight_bg_color: $white_fa;

$code_width: 57%;
$annotation_width: 41%;

div.annotated_blueprint {
  margin-top: 60px;
  div.code_scroller {
    background-color: $code_bg_color;
    border: 0.4em solid $explained_code_bg_color;
    border-left: 0;
    border-right: 0;
    border-radius: 3px;
    width: $code_width;
    margin-right: 0px;
    margin-left: auto;
    background-color: $explained_code_bg_color;
    color: $explained_code_fg_color;
    overflow-x: scroll;
    div { 
      overflow-x: visible !important; 
      overflow-y: visible !important; 
    }  
    div.code_viewer {
      width: auto;
      height: 100%;
      div.block {
        @include flex();
        -webkit-align-items: center;
        align-items: center;
        z-index: -10;
      }
      div.block > div:last-child {
          @include pre();
          border: 0;
          border-radius: 0;
          padding: 0px 0.6em;
          background-color: $explained_code_bg_color;
          color: $explained_code_fg_color;
          min-width: 100%;
          margin-top: 3px;
          margin-bottom: 3px;
      }
      div.block > div.annotations_wrapper1 {
        font-family: $fonts;
        div.annotations_wrapper2 {
          // seems we have to use abs positioning because other techniques
          // (translation, negative margins, etc) don't show up outside the scroll-x
          position: absolute;
          @include transform('translateX(-100%) translateY(-50%)');
          width: $annotation_width;
          max-width: 28em;
          
          @include flex();
          -webkit-align-items: center;
          align-items: center;
          div.annotations {
            // positioning and sizing
            height: auto;
            width: 100%;
            padding: 8px 8px;
            padding-bottom: 11px;
            color: $explained_text_highlight_fg_color;

            div.short {
              font-size: 125%;
              text-align: right;
            }
            div.long {
              display: none;
              
              font-size: 85%;
              p { margin: 8px 0 8px 0; }
              p:first-child { margin-top: 0; }
              p:last-child { margin-bottom: 0; }
            }
          }
          div.connector {
            height: 7px;
            width: 48px;
          }
        }
      }
      div.block:hover > div:last-child {
          background-color: $explained_code_highlight_bg_color;
          color: $explained_code_highlight_fg_color;
          // TODO the bg color doesn't apply to regions which are only visible after a scroll-right :(
          // to fix this i think we can us JS to set width of div.block to the width of this, the code last-child
      }
      div.block:hover {
        z-index: 30 !important;
        div.annotations_wrapper1 {
          z-index: 30 !important;
        }
        div.annotations {
          background-color: $explained_text_highlight_bg_color;
          border: 4px solid $explained_code_highlight_bg_color;
          border-radius: 8px;
          text-align: center; 
          box-shadow: 2px 10px 20px $gray_aa;
          div.short {
            font-size: 100% !important;
            text-align: center !important;
            color: $text_color;
            margin-bottom: 8px;
            font-weight: normal; 
            z-index: 10;
          }
          div.long { display: inherit !important; }
        }
        div.connector {
          border-style: solid;
          width: 16px !important;
          border-width: 5px 0px 5px 6px;
          border-style: solid;
          border-color: transparent transparent transparent $explained_code_highlight_bg_color;
        }
      }
      // highlighting of key code segments
      div.block > div:last-child .ann_highlight { 
        font-weight: bold; 
        color: #e8f0e0; 
      }
      div.block:hover > div:last-child .ann_highlight { 
        color: #f8fff8; 
      }
    }
  }
  div.code_viewer:hover {
    div.annotations div.short {
      color: $gray_aa;
    }
  }
  
  // have an initial notice
  div.code_scroller {
    div.initial_notice {
      height: 0;
      width: 0;
      overflow-x: visible;
      overflow-y: visible;
    }
    div.initial_notice > div {
      z-index: 10;
      background-color: transparentize($gray_aa, 0.1);
      position: relative;
      width: 0;
      height: 0;
      @include flex();
      -webkit-align-items: top;
      align-items: top;
    }
    div.initial_notice > div > div {
      padding-top: 24px;
      font-size: 125%;
      font-weight: bold;
      text-align: center;
      width: 100%;
      .ann_light {
        padding-top: 12px;
        font-weight: 300;
      }
      .ann_play {
        display: block;
        padding-top: 24px;
        padding-bottom: 24px;
        font-size: 600%;
      }
    }
  }
  div.code_scroller:hover div.initial_notice {
    display: none;
  }
}
