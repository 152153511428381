
/* LANDING PAGE
   ----------------------------------------------------------------------- */

.home {
  .navbar-brand .brand-apache > img {
    height: 200px;
    top: -80px;
  }

  .hero {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%23eeeeee' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
    overflow: hidden;
    padding: 100px 0 30px 0;
  }

  .hero {
    h1 {
      font-family: "Arial Rounded MT Bold", $header_fonts;
      font-size: 6em;

      .text-apache {
        color: #808285;
      }
      .text-brooklyn {
        color: $brooklyn_green;
      }
    }
    p {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  }

  .about, .social {
    margin-top: 50px;
    margin-bottom: 50px;

    h3.use {
      margin-top: 3em;
      margin-bottom: 2em;
    }
  }

  .get-started {
    margin-bottom: 0;
  }

  .shell {
    margin-top: 50px;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    background: #fff;
    overflow: hidden;

    .shell-toolbar {
      border-bottom: 1px solid #f1f1f1;
      padding: 0 4px;
      overflow: hidden;

      i {
        background: #f00;
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 100px;
        float: left;
        margin: 8px;
        margin-right: 0;

        &.red {
          background: #ff5f57;
        }
        &.yellow {
          background: #ffbd2e;
        }
        &.green {
          background: #2ace42;
        }
      }
      span {
        text-align: center;
        max-width: 150px;
        margin: auto;
        display: block;
        font-family: Arial;
        margin-top: 6px;
        font-size: 12px;
        color: #999;
      }
    }
    .highlight {
      background-color: transparent;
    }
    pre {
      background-color: #000;
      padding: 5px 10px;
      color: #f1f1f1;
      margin: 0;

      code.language-bash {
        color: inherit;
        background: transparent;
      }
    }
  }

  .social a {
    color: inherit;
    transition: color 0.3s ease;
    &:hover {
      color: $brooklyn_green;
    }
  }
}
