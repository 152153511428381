
$fonts: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
$header_fonts: Avenir, $fonts;

// colors

/* this green is approx what is in the logo (taken from image picker) */
$brooklyn_green: #6db34b;
$bootstrap_theme_green_gradient_upper: #5cb85c;
$bootstrap_theme_green_gradient_lower: #48a648;
$vibrant_bg_green: $bootstrap_theme_green_gradient_lower;
$vibrant_fg_green: #483;

$white_fa: #fafafa;
$white_f5: #f5f5f5;
$white_ee: #eee;
$white_dd: #ddd;  // for borders and tooltip bg
$gray_aa: #aaa;
$gray_88: #888;
$gray_66: #666;

$blackish: #393939;

$bootstrap_blue_border: #428bca;

// roles for colors

$text_color: $blackish;

$a_color: $vibrant_fg_green;
$a_hover_color: $brooklyn_green;

//$code_bg_color: #e8eded;
$code_bg_color: $white_f5;

$footer_icon_gray: $gray_88;
$fonts: "myriad-pro", "myriad pro", "myriad-pro-1", "Helvetica Neue", Helvetica, Arial, sans-serif;
$header_fonts: "Open Sans", $fonts;


// colors

$cloudsoft_green: #24A27D;
$cloudsoft_blue: #102752;

$blueish: #102752;
$whitish: #f5f6fa;

$whitish_1darker: #f0f1f8;
$whitish_2darker: #e8eaf2;

// roles for colors

$text_color: $blueish;
$background_color: $whitish;
 
$a_color: $cloudsoft_green;
$a_hover_color: $cloudsoft_green;


@import 'util';
@import 'basic';
@import 'tooltips';
@import 'code_blocks';
@import 'menu';
@import 'search';
@import 'footer';
@import 'main_container';

@import 'archive_warning';
@import 'landing';
@import 'blueprint_tour';
@import 'feature_list';
