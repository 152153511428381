/* FOOTER
   ----------------------------------------------------------------------- */

/* sticky footer */
html {
    main {
        min-height: calc(100vh - 50px - 3.5em);
    }

    footer {
        height: 3.5em;
        border-top: 1px solid #eee;

        p {
            height: 2em;
        }

        .octicon-footer {
            color: $footer_icon_gray;
            font-size: 130%;
            position: relative;
            top: 2px;
            padding-left: 6px;
        }
        a:hover .octicon-footer {
            color: $brooklyn_green;
        }
    }
}
