/* WEBSITE MENUS
   ----------------------------------------------------------------------- */

.navbar-brand > img {
  margin-top: -4px;
}
.navbar a.navbar-btn {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  padding: 6px 12px;
}

/* pop-up top-menu */
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  background-color: $white_f5;
  color: $brooklyn_green;
      background-image: none;
}

/* side menu */
.side-menu {
  font-family: Avenir, $fonts;
  font-size: 14px;
  font-weight: 400;
  position: fixed;
  h4.with_following {
    margin-bottom: 0;
  }
  h4.with_preceding {
    margin-top: 0;
  }
  h4.with_following a {
    border-bottom-right-radius: 0 !important ;
    border-bottom-left-radius: 0 !important ;
    border-bottom: 0;
  }
  h4.with_preceding a {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 0;
  }
  a, a.breadcrumb.breadcrumb0 { padding-left: 15px; padding-top: 8px; padding-bottom: 6px; }
  a.breadcrumb.breadcrumb1 { padding-left: 30px; }
  a.breadcrumb.breadcrumb2 { padding-left: 45px; }
  a.breadcrumb.breadcrumb3 { padding-left: 60px; }
  /* further crumbs all get the same */
  a.breadcrumb { padding-left: 70px; }
  
  div.sub-item {
    background-color: $bootstrap_theme_green_gradient_lower;
    border-left: 1px solid $bootstrap_blue_border;
  } 
  div.sub-item:last-child {
    border-bottom: 1px solid $white_dd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  div.sub-item:last-child a.sub-item:last-child {
    border-bottom-right-radius: 4px !important;
  }
  a.with-sub-item {
    /* border-bottom: 1px solid $white_dd; */
    border-bottom: 0;
  }
  a.sub-item { 
    border-radius: 0 !important;
    border-bottom: 0;
    margin-left: 12px;
    padding-left: 10px; 
    padding-top: 8px; 
    padding-bottom: 8px; 
  }
  a.sub-item.list-group-item.active {
    background-color: $white_f5;
    // the below is needed to achieve the above, since bg-image is already set
    background-image: -webkit-linear-gradient(top, $white_f5 0%, $white_f5 100%);
    background-image: linear-gradient(to bottom, $white_f5 0%, $white_f5 100%);
    
    border-color: $white_dd;
    color: $text_color;
    text-shadow: none;
    // not sure why the bottom border is visible at all, and stays on hover, but vanishes on 'active';
    // but this sorts it out (taking 1 away from padding)
    margin-bottom: 0;
    padding-bottom: 7px;
  }
  a.sub-item.list-group-item.active:hover {
    color: $a_hover_color;
  }
}
