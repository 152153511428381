/* GENERAL UTILS
   ----------------------------------------------------------------------- */

@mixin flex() {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin transform($tfn) {
  -webkit-transform: #{$tfn};
  -moz-transform: #{$tfn};
  -o-transform: #{$tfn};
  transform: #{$tfn};
}
   
@mixin flip() {
  filter: FlipH;
  @include transform('scaleX(-1)');
  -ms-filter: "FlipH";
}

.flip {
  @include flip;
}

div.no-space-in-list {
  p {
    margin: 0;
  }
}